@font-face {
    font-family: 'agency-fb';
    src: url('.././fonts/agency-fb.ttf');
  }

.work_container {
    display: flex;
    background-image: url("../img/BACKGROUND.jpg");
    background-size: cover;
    color: white;
    height: 100%;
    flex-direction: column;
}

.work_container h3 {
    display: flex;
    justify-content: center;
    font-family: 'agency-fb', arial;

}

#card_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 3rem;
}

.card {
    margin: 2rem;
    width: 21rem;
    height: 37rem;
    background: hsla(0, 51%, 49%, 0.062);
    /*
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    */
}

.card_content {
    color: white;
    font-family: 'agency-fb', arial;
    margin: 1rem;
    font-size: 1.3rem;
}
.card_content h3 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.tecnologias {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    color: #ff01be;
}

.button_card {
    border: 0px;
    background-color: transparent;
    filter: invert(1);
    cursor: pointer;
    margin-top: 2rem;
    margin-left: 2rem;
}

.button_card img{
    width: 1rem;
}

#work_title {
    margin-top:2rem;
    font-size: 2rem;
}

.imagen_port {
    width: 21rem;
}

.footer {
    display: flex;
    margin-top: 10rem;
    justify-content: center;
    align-items: center;
    font-family: 'agency-fb', arial;
}

.button-footer {
    border: 0px;
    padding: 10px;
    background-color: transparent;
    cursor: pointer;
  }

.footer p {
    font-size: 1.2rem;
    font-family: 'agency-fb', arial;
}


@media (max-width: 540px) {
    .work_container {
        background: none;
    }
}

