@font-face {
    font-family: 'agency-fb';
    src: url('.././fonts/agency-fb.ttf');
  }

.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../img/BACKGROUND3.jpg");
    background-position: 10%;
    background-size: cover;
    color: white;
    height: auto;
    font-family: 'agency-fb', arial;
    font-size: 2rem;
    padding-bottom: 30rem;
}

#about_title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#text_container {
    margin-top: 1rem;
    width: 40rem;
}

#ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1rem;
    font-size: 1.5rem;
    margin: 3rem;
}

#ul li{
   margin-right: 2.5rem;
}

@media (max-width: 540px) {
    #text_container {
        margin-top: 8rem;
        width: 70%;
        font-size: 1.7rem;
        margin-bottom: 5rem;
    }
    .about-container {
        background: none;
    }
  }