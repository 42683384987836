@font-face {
  font-family: 'agency-fb';
  src: url('.././fonts/agency-fb.ttf');
}

.nav-container {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  background-color: transparent;
  justify-content: flex-end;
  padding: 1rem;
  flex-direction: row;
  width: 100vw;
}

.nav {
  margin-left: 50px;

}

.nav-link {
  margin-left: 50px;
}

.nav-button {
  border: 0px;
  padding: 0.7rem;
  color: white;
  background-color: transparent;
  font-family: 'agency-fb', arial;
  font-weight: 800;
  font-size: 2rem;
  overflow-y: hidden;
}

.nav-button:hover {
  border: 0px;
  cursor: pointer;
  color: rgb(248, 250, 114);
}

.icon_menu {
  color: white;
  
}

.nav-button {
  cursor: pointer;
  position: relative;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
}
  
.nav-button:focus,
.nav-button:hover {
  color: rgb(248, 250, 114);
}

.nav-button:focus:after,
.nav-button:hover:after {
  width: 100%;
  left: 0%;
}
  
.nav-button:after {
  content: "";
  pointer-events: none;
  bottom: -2px;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: rgb(248, 250, 114);
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, left;
}

.lets_connect {
  border: 0px;
  padding: 0.7rem;
  color: black;
  background-color: white;
  font-family: 'agency-fb', arial;
  font-size: 2rem;
  margin: 1.2rem;
  text-decoration:none;
  font-weight: 600;
}

.lets_connect:hover {
    border: 0px;
    cursor: pointer;
    color: black;
    background-color: rgb(248, 250, 114);
    font-family: 'agency-fb', arial;
    font-size: 2rem;
    margin: 1.2rem;
    text-decoration:none;

}

@media (max-width: 540px) {
  .nav-container {
    padding: 0;
    padding-top: 30px;
    justify-content: center;
  }
  .nav {
    margin-left: 10px;
  }
  .nav-link {
    margin-left: 0px;
  }
}

@media (max-width: 350px) {
  .nav {
    margin-left: 10px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    position: relative;
    left: -0.5rem;
    height: auto;
  }
  .nav-container {
    position: static;
    padding: 0;
  }
}