@font-face {
    font-family: 'agency-fb';
    src: url('.././fonts/agency-fb.ttf');
}

.home-container {
    display: flex;
    background-image: url("../img/BACKGROUND.jpg");
    background-size: cover;
    color: white;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
}

#div_navbar {
    position: relative;
}

#container-content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-family: 'agency-fb', arial;
    padding-left: 20px;
    overflow-y: hidden;
}

#texto-landing {
    width: 33rem;
    font-family: 'agency-fb', arial;
    font-weight: 600;
}

#my-name {
    font-size:2rem;
    color: #ff01be;
}
#ivo-rojas {
    font-size: 8rem;
}

#introduction {
    font-size: 1.5rem;
    font-family: 'agency-fb', arial;
}

#logo {
    position: relative;
    right: 4rem;
    animation: float 4s ease-in-out infinite;
}

@keyframes float {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 20px); }
    100%   { transform: translate(0, -0px); }   
}

@media (max-width: 540px) {
    #container-content {
    display: flex;
    flex-direction: column;
    background: none;
    }
    #texto-landing {
        margin-top: 10vw;
        width: 70%;
        margin-bottom: 2rem;
        background: none;
    }
    #ivo-rojas {
        position: relative;
        left: -0.3rem;
        font-size: 7.5rem;
        background: none;
    }
    #logo {
        display: none;
    }
    /* Se quita el background para formato Celulares */ 
    .home-container {
        background: none;
    }
  }

  @media (max-width: 380px) {
    .home-container {
        justify-content: flex-start;
        padding-top: 2rem;
    }
    #ivo-rojas {
        position: relative;
        left: -0.3rem;
        font-size: 6.5rem;
    }
    #texto-landing {
        padding-top: 3.4rem;
    }
  }